  .about {
    background-color: black;
    color: white;
    height: 100%;
    width: 100%;
    padding-top: 1px;
  }

  .about-section {
    padding-top: 300px;
    padding-bottom: 300px;
    background-color: var(--background);
    color: var(--text);
    font-size: 15px;
    font-family: var(--fontStack);
    font-weight: var(--fontWeightRegular);
    transition: var(--durationM) ease;
    transition-property: background, opacity;
    color: whitesmoke;
    width: 100%;
    overflow-x: hidden;
    font-size: 30px;
  }

  .quote-card-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    border-radius: 10px;
    background-color: var(--background);
    color: var(--text);
    font-size: 15px;
    font-family: var(--fontStack);
    font-weight: var(--fontWeightRegular);
    transition: var(--durationM) ease;
    transition-property: background, opacity;
    color: whitesmoke;
    width: 100%;
    overflow-x: hidden;
    font-size: 30px;
  }

  .about-activity {
    justify-content: left;
    text-align: left;
    font-size: 20px;
  }

  .toolstack {
    display: inline-flex;
    flex-direction: row;
    justify-content: "center";
    padding-bottom: "50px";
  }

  .techstack {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 50px;
  
  }

  .tech-icons {
    font-size: 2.5em !important;
    margin: 15px !important;
    padding: 10px !important;
    opacity: 0.93 !important;
    border: 1.7px solid rgba(200, 137, 230, 0.637) !important;
    vertical-align: middle !important;
    text-align: center !important;
    border-radius: 5px !important;
    display: table !important;
    box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
    overflow: hidden !important;
    transition: all 0.4s ease 0s !important;
  }

  .blockquote-footer {
    padding-top: -5px;
    text-align: left;
    font-size: 25px;
    color: #a588c0 !important;
    padding-bottom: 30px;
  }

  @media (max-width: 767px) {
    .about-img {
      padding-top: 0 !important;
    }
  }

  .tech-icons:hover {
    transform: scale(1.05) !important;
    overflow: hidden !important;
    border: 2.2px solid rgba(197, 115, 230, 0.883) !important;
  }
  .tech-icon-images {
    padding: 20px !important;
    line-height: 1.6 !important;
  }

  .project-heading {
    color: whitesmoke !important;
    font-size: 2.3em !important;
    font-weight: 500 !important;
    padding-top: 10px !important;
  }

.logo {
  width: 100%;
  height: 100%;
  border-radius: 70%;
}

.points {
  color: #fff;
  font-size: 15px;
  padding-left: 0.25rem;
  letter-spacing: 0.05em;
}

.title {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}

.experience {
  margin-top: 1.25rem; /* mt-5 */
  list-style-type: disc; /* list-disc */
  margin-left: 1.25rem; /* ml-5 */
  margin-top: 0.5rem;
  padding-bottom: 30px;
}

.exp-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.exp {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
}

.pbody {
  color: #6b7280;
  font-size: 16px;
  font-weight: 600;
}

.vertical-timeline-element-date {
  left: 90%;
}

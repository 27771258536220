@layer layout {
    .navbar {
      --headerNavFontSize: calc((16 / 16) * 1rem);
  
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      padding-top: 10px;
      width: var(--space2XL);
      position: relative;
      top: var(--spaceOuter);
      left: var(--spaceOuter);
      bottom: var(--spaceOuter);
      z-index: var(--zIndex4);
      isolation: isolate;
      background-color: black;
      margin-top: calc(var(--spaceOuter) * -1);
  
      @media (--mediaMobile) {
        --headerNavFontSize: calc((22 / 16) * 1rem);
      }
  
      @media (--mediaMobile), (--mediaMobileLS) {
        bottom: auto;
      }
    }
  
    .logo {
      display: flex;
      position: relative;
      padding: var(--spaceS) var(--spaceS) var(--spaceL);
      z-index: var(--zIndex2);
  
      @media (--mediaMobile) {
        padding-bottom: var(--spaceS);
      }
    }

    .navbar-toggle {
      position: relative !important;
      background-color: transparent !important;
      border-color: transparent !important;
    }

    .navbar-brand {
      height: 50px;
      color: whitesmoke !important;
    }

    .navbar-nav .nav-item a:hover::after {
      width: 100%;
      background-color: whitesmoke;
    }
  
    .nav {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      flex: 1 1 auto;
      position: relative;
      top: 0;
      padding-top: 5px;
      max-width: var(--space2XL);
      opacity: 100%;
      
  
      @media (--mediaMobile), (--mediaMobileLS) {
        display: none;
      }
    }
  
    .navList {
      transform: rotate(180deg);
      display: flex;
      flex-direction: row-reverse;
      position: relative;
      writing-mode: vertical-lr;
    }
  
    .navLink {
      padding: var(--spaceM);
      color: whitesmoke;
      font-weight: var(--fontWeightMedium);
      font-size: var(--headerNavFontSize);
      text-decoration: none;
      position: relative;
      transition: color var(--durationS) ease 0.1s;
      line-height: 1;
      display: inline-flex;
      align-items: center;
      padding-top: 5px;
  
      &:hover,
      &:active,
      &[aria-current='page'] {
        color: var(--textBody);
      }
  
      &::after {
        content: '';
        position: absolute;
        inset-inline-start: var(--spaceS);
        inset-inline-end: var(--spaceS);
        block-size: 4px;
        background: var(--accent);
        background-color: aqua;
        transform: scaleY(0) translateZ(0);
        transform-origin: bottom;
      }
  
      @media (--mediaUseMotion) {
        &::after {
          transition: transform var(--durationM) var(--bezierFastoutSlowin);
        }
      }
  
      &:hover::after,
      &:active::after,
      &[aria-current='page']::after {
        transform: scaleY(1) translateZ(0);
        transform-origin: top;
      }
    }
  
    .navIcons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: var(--zIndex2);
  
      @media (--mediaMobile), (--mediaMobileLS) {
        flex-direction: row;
        position: absolute;
        bottom: var(--spaceXL);
        left: var(--spaceXL);
      }
  
      @media (max-width: 820px) and (max-height: 420px) {
        left: var(--spaceL);
        transform: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  
    .navIconLink {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--spaceS);
      width: var(--space2XL);
      height: var(--space2XL);
      color: var(--textLight);
      transition: color var(--textLight)  var(--bezierFastoutSlowin);
  
      &:hover,
      &:active {
        color: var(--textLight);
      }
    }
  
    .navIcon {
      fill: currentColor;
      transition: fill var(--durationM) ease;
    }
  
    .mobileNav {
      --backdrop: blur(24px) saturate(120%);
  
      position: fixed;
      inset: 0;
      background: color-mix(in lab, var(--background) 70%, transparent);
      transform: translate3d(0, -100%, 0);
      transition-property: background;
      transition-duration: var(--durationL);
      transition-timing-function: var(--bezierFastoutSlowin);
      display: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      -webkit-backdrop-filter: var(--backdrop);
      backdrop-filter: var(--backdrop);
  
      @media (--mediaMobile), (--mediaMobileLS) {
        display: flex;
      }
  
      @media (--mediaUseMotion) {
        transition-property: transform, background;
      }
  
      &[data-visible='true'] {
        transform: none;
      }
    }
  
    .mobileNavLink {
      width: 100%;
      font-size: var(--headerNavFontSize);
      text-align: center;
      text-decoration: none;
      color: var(--textBody);
      padding: var(--spaceL);
      opacity: 0;
      transition-property: opacity;
      transition-duration: var(--durationS);
      transition-timing-function: var(--bezierFastoutSlowin);
      transition-delay: var(--transitionDelay);
      position: relative;
      top: calc(var(--spaceM) * -1);
  
      @media (--mediaUseMotion) {
        transition-property: transform, opacity;
        transform: translate3d(0, calc(var(--spaceXL) * -1), 0);
      }
  
      @media (max-width: 820px) and (max-height: 420px) {
        top: auto;
      }
  
      @media (--mediaMobileS), (max-height: 360px) {
        --headerNavFontSize: calc((18 / 16) * 1rem);
      }
  
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: var(--space3XL);
        left: var(--space3XL);
        height: 4px;
        background: var(--accent);
        transform: scaleX(0) translateY(-1px);
        transform-origin: right;
      }
  
      @media (--mediaUseMotion) {
        &::after {
          transition: transform var(--durationM) var(--bezierFastoutSlowin);
        }
      }
  
      &:hover::after,
      &:active::after,
      &:focus::after {
        transform: scaleX(1) translateY(-1px);
        transform-origin: left;
      }
  
      &[data-visible='true'] {
        opacity: 1;
        transform: none;
      }
    }
  }
  